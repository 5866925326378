import React from "react";
import { ReactComponent as GetPlayStoreSvg } from "assets/svgs/GetPlayStore.svg";
import { ReactComponent as GetAppStoreSvg } from "assets/svgs/GetAppStore.svg";

const DownloadApp = () => {
  return (
    <div className="flex flex-row mt-3 mb-3">
      <a href="http://play.googl.ccom">
        <GetAppStoreSvg className="" />
      </a>
      <span className="mx-1" />
      <div>
        <GetPlayStoreSvg />
      </div>
    </div>
  );
};

export default DownloadApp;
