import React, { useEffect, useLayoutEffect, useReducer } from "react";
import GoogleAnalyticRouter from "components/GoogleAnalyticRouter";
import { Route, Switch } from "react-router-dom";
import Footer from "Footer";
import Header from "Header";
import AppContext from "context";
import { bookingReducer, initialState, SET_AUTH } from "reducer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useToken from "hooks/useToken";
import AdminContainer from "pages/admin/AdminContainer";
import PublicContainer from "PublicContainer";
import CustomerContainer from "pages/customer/CustomerContainer";
import MobileNav from "MobileNav";
import { useMutation } from "react-query";
import { logger } from "common/utils";
import { getMe } from "pages/account/api";

export default function App() {
  const [
    {
      passengers,
      confirmation,
      auth,
      selectedBooking,
      storeUpdated,
      session_id: sessionId,
      hasReadTermsAndCondition,
      authToken,
      selectedAirlineConfig,
    },
    dispatch,
  ] = useReducer(bookingReducer, initialState);

  const { mutate: mutateGetMe } = useMutation(getMe, {
    onSuccess(res) {
      logger("Getttting me ", res)
      dispatch({type: SET_AUTH, payload: res})
    }
  })

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      const width = window.innerWidth;
      console.log("Width = ", width);
    });
  }, []);


  useEffect(() => {
    if (authToken) {
      mutateGetMe()
    }
  }, [authToken, mutateGetMe])

  return (
    <div className="flex flex-col bg-gray-100 sm:overflow-hidden overflow-y-auto h-full min-h-screen mx-auto md:m-0">
      <GoogleAnalyticRouter />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <AppContext.Provider
        value={{
          passengers,
          confirmation,
          auth,
          selectedBooking,
          storeUpdated,
          dispatch,
          sessionId,
          hasReadTermsAndCondition,
          authToken,
          selectedAirlineConfig,
        }}
      >
        <Header />
        <main className="mt-[30px] mb-3">
          <React.Suspense fallback={""}>
            <Switch>
              <Route path="/admin" component={AdminContainer} />
              <Route path="/app" component={CustomerContainer} />
              <Route path="" exact={true} component={PublicContainer} />
            </Switch>
          </React.Suspense>
        </main>

        <Footer />
        <div className="md:hidden">
          <MobileNav />
        </div>
      </AppContext.Provider>
    </div>
  );
}
