import React from "react";
import { ReactComponent as MobileNavHome } from "assets/svgs/MobileNavHome.svg";
import { ReactComponent as MobileNavBooking } from "assets/svgs/MobileNavBooking.svg";
import { ReactComponent as MobileNavProfile } from "assets/svgs/MobileNavProfile.svg";
import { Link } from "react-router-dom";
import { RouteEnum } from "routes";

const MobileNav = () => {
  return (
    <div className="h-[12vh] bg-white shadow-cards w-full fixed top-[88vh]">
      <div className="flex flex-row justify-around items-center  py-[24px]">
        <Nav to={RouteEnum.Landing}>
          <MobileNavHome  />
        </Nav>
        <Nav  title="Booking" to={RouteEnum.CustomerBooking}>
          <MobileNavBooking />
        </Nav>
        <Nav title="Profile" to={RouteEnum.CustomerProfile}>
          <MobileNavProfile  />
        </Nav>
      </div>
    </div>
  );
};

const Nav = ({ title = "Home", to = "/", children }) => {
  return (
    <Link to={to} className="flex flex-col items-center">
      {children}
      <span className="text-sm">{title}</span>
    </Link>
  );
};

export default MobileNav;
