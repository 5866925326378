import React from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga4";
const GoogleAnalyticRouter = ({ history }) => {
  history.listen((location, action) => {
    ReactGA.set({ page: location.pathname });
    console.log("Recoring page view ", location.pathname);
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  });

  return <div></div>;
};

export default withRouter(GoogleAnalyticRouter);
