import Container from "components/Container";
import RouteComposer from "components/RouteComposer";
import React from "react";
import { Link, Switch } from "react-router-dom";
import {  CustomerRoutes, RouteEnum } from "routes";

const CustomerContainer = () => {
  // TODO check if the user is authenticated
  const menus = [
    {
      to: RouteEnum.CustomerBooking,
      name: "Bookings",
    },
    {
      to: RouteEnum.CustomerProfile,
      name: "Profile",
    },


  ];
  return (
    <Container>
      <div className="flex flex-row w-full">
        <div className="hidden md:block md:w-1/5 mr-8">
          <div className="flex flex-col space-y-3">
            {menus.map((menu) => {
              return (
                <div className="flex flex-row justify-center items-center border border-site-secondary rounded-lg bg-site-secondary text-white h-12 text-lg uppercase">
                  <Link to={menu.to} className="hover:text-site-primary hover:cursor-pointer">
                    {menu.name}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>

        <div className="w-full md:w-4/5">
          <RouteComposer
            routes={CustomerRoutes}
            wrapper={(children) => {
              return <Switch>{children}</Switch>;
            }}
          />
        </div>
      </div>
    </Container>
  );
};

export default CustomerContainer;
