import React, { useContext } from "react";
import { ReactComponent as CallSvg } from "assets/svgs/Call.svg";
import { ReactComponent as UserSvg } from "assets/svgs/User.svg";
import { ReactComponent as MenuSvg } from "assets/svgs/Menu.svg";
import { ReactComponent as HambugerSvg } from "assets/svgs/Hambuger.svg";
import { ReactComponent as Logo } from "assets/svgs/fc_logo.svg";
import { Link } from "react-router-dom";
import AppContext from "context";
import { RouteEnum } from "routes";

const Header = () => {
  const { authToken } = useContext(AppContext);
  return (
    <header className="flex w-full px-8 mt-8 md:px-[144px]">
      <div className="flex">
        <Link to="/">
          <Logo />
        </Link>
      </div>

      <div className="flex ml-auto ">
        <nav className="hidden md:flex f md:flex-row md:space-x-8 md:justify-center md:items-center font-bold text-lg leading-6 text-site-secondary ">
          <Link to="/" className=" hover:underline hover:text-site-primary">
            Travel
          </Link>

          <Link
            to={RouteEnum.CustomerBooking}
            className="hover:underline hover:text-site-primary"
          >
            Manage Booking
          </Link>
          <Link
            to="/about-us"
            className=" hover:underline hover:text-site-primary"
          >
            About Us
          </Link>

          {authToken ? (
            <Link
              to="/logout"
              className="flex flex-row items-center justify-center bg-site-secondary rounded py-2  px-3  text-white font-bold hover:text-site-primary "
            >
              Logout
            </Link>
          ) : (
            <Link
              to="/login"
              className="flex flex-row items-center justify-center bg-site-secondary rounded py-2  px-3  text-white font-bold hover:text-site-primary "
            >
              Login
            </Link>
          )}
        </nav>

        <div className="mr-2 block md:hidden">
          <HambugerSvg />
        </div>
      </div>


    </header>
  );
};

export default Header;
