import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import initailizerHttp from "common/Initializer";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import DateAdapterDayJs from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ReactGA from "react-ga4";

import "assets/fonts/Nexa/Nexa-Regular.ttf";
import { QueryClient, QueryClientProvider } from "react-query";

// const TRACKING_ID = "307729178"; // YOUR_OWN_TRACKING_ID
const TRACKING_ID = "G-C4TJ6L6VKD"; // YOUR_OWN_TRACKING_ID
// const TRACKING_ID = "UA-223434180-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

initailizerHttp();
const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={DateAdapterDayJs}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </BrowserRouter>
    </LocalizationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
