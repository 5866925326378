import { v4 as uuidv4 } from "uuid";

export const defaultPassengerDetail = {
  title: "Mr",
  gender: "Male",
  first_name: "",
  last_name: "",
  dob: new Date("01-01-1940"),
  is_child: false,
  passenger_type: "adult_fare",
  key: uuidv4(),
};

// state
export const initialState = {
  passengers: [{ ...defaultPassengerDetail }],
  storeUpdated: null,
  session_id: "",
  isLoading: false,
  confirmation: {
    email: "",
    phone: "",
    should_create_account: true,
  },
  auth: {
    username: "Guest",
    last_login: null,
    user_type: "PASSENGER",
    _id: null
  },
  authToken: null,
  username: "",
  selectedBooking: {},
  flightResults: [],
  hasReadTermsAndCondition: false,
  selectedAirlineConfig: {}
};

// actions
export const ADD_ADULT = "ADD_ADULT";
export const REMOVE_ADULT = "REMOVE_ADULT";
export const IS_CHILD = "IS_CHILD";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_CONFIRMATION = "SET_CONFIRMATION";
export const SET_BOOKING = "SET_BOOKING";
export const SET_FLIGHT_RESULTS = "SET_FLIGHT_RESULTS";
export const SET_BOOKING_SESSION = "SET_BOOKING_SESSION";
export const SET_PASSENGERS = "SET_PASSENGERS";
export const UPDATE_PASSENGER = "UPDATE_PASSENGER";
export const STORE_UPDATE = "STORE_UPDATE";
export const HAS_READ_TERMS_CONDITION = "HAS_READ_TERMS_CONDITION";
export const SET_USERNAME = "SET_USERNAME";
export const SET_TOKEN = "SET_TOKEN";
export const SET_SELECTED_AIRLINE_CONFIG = "SET_SELECTED_AIRLINE_CONFIG";
export const SET_AUTH = "SET_AUTH";
export const RESET_SESSION = "RESET_SESSION"

// reducer
export const bookingReducer = (state = initialState, action) => {
  const { type = "", payload = "" } = action;
  let { passengers: prevPassengers } = state;

  switch (type) {
    case STORE_UPDATE:
      return {
        ...state,
        storeUpdated: Date.now(),
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case ADD_ADULT:
      return {
        ...state,
        passengers: [...prevPassengers, payload],
      };

    case REMOVE_ADULT:
      return {
        ...state,
        passengers: [...prevPassengers.filter((p) => p.key !== payload)],
      };

    case SET_CONFIRMATION:
      return {
        ...state,
        confirmation: { ...payload },
      };

    case SET_BOOKING:
      return {
        ...state,
        selectedBooking: { ...payload },
      };
    case SET_FLIGHT_RESULTS:
      return {
        ...state,
        flightResults: [...payload],
      };
    case SET_BOOKING_SESSION:
      return {
        ...state,
        session_id: payload,
      };
    case SET_PASSENGERS:
      return {
        ...state,
        passengers: [...payload],
      };
    case UPDATE_PASSENGER:
      const { key, info } = payload;
      const passengerToUpdate = {
        ...prevPassengers.find((p) => p.key !== key),
        ...info,
      };

      return {
        ...state,
        passengers: [
          ...prevPassengers.filter((p) => p.key !== key),
          passengerToUpdate,
        ],
      };
    case HAS_READ_TERMS_CONDITION:
      return {
        ...state,
        hasReadTermsAndCondition: payload,
      };

    case SET_USERNAME: {
      return {
        ...state,
        username: payload,
      };
    }
    case SET_TOKEN: {
      return {
        ...state,
        authToken: payload,
      };
    }

    case SET_SELECTED_AIRLINE_CONFIG: {
      return {
        ...state,
        selectedAirlineConfig: {...payload},
      };
    }

    case SET_AUTH: {
      return {
        ...state,
        auth: {...payload},
      };
    }

    case RESET_SESSION: {
      return {
        ...state,
        session_id: null,
        passengers: [{ ...defaultPassengerDetail }]
      }
    }

    default:
      return { ...state };
  }
};
