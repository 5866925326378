import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as FbSvg } from "assets/svgs/fb.svg";
import { ReactComponent as InstSvg } from "assets/svgs/insta.svg";
import { ReactComponent as TwitterSvg } from "assets/svgs/twitter.svg";
import DownloadApp from "pages/components/DownloadApp";

const Footer = () => {
  const footerLinks = [
    {
      name: "Contact Us",
      link: "/contact-us",
    },
    {
      name: "Terms & Policy",
      link: "/contact-us",
    },

    {
      name: "About Us",
      link: "/about-us",
    },
    {
      name: "Affliate",
      link: "/join-affliate",
    },
  ];
  return (
    <footer className="mt-auto">
      <div className="md:h-[267px]  flex  flex-col md:flex-row md:justify-between md:items-start bg-site-secondary text-white  px-8 md:px-[144px] py-8 md:py-[51px] space-y-4 md:space-y-0">
        <div className="flex flex-col">
          <div className="font-bold text-[21px] leading-[25.2px]">
            <Link to="/">FlightCompare</Link>
          </div>
          <div className="text-white text-[12px]">Copyright ©️ 2022</div>
          <DownloadApp />
        </div>
        <div className="space-y-1">
          <div className="font-bold text-[21px] leading-[25.2px]">Company</div>
          <div className="flex flex-col space-y-2">
            {footerLinks.map(({ name, link }) => {
              return (
                <Link
                  to={link}
                  key={name}
                  className="hover:underline hover:text-site-primary"
                >
                  {name}
                </Link>
              );
            })}
          </div>
          <div></div>
        </div>

        <div className="flex flex-col space-y-1">
          <span className="font-bold text-[21px] leading-[25.2px]">
            GET IN TOUCH
          </span>
          <span className="font-[400] text-gray-400  text-base leading-6">
            General enquiries
          </span>
          <span className="font-normal text-base leading-6">
            contact@shorthaul.app
          </span>
          <span className="font-normal text-base leading-6">
            +234080883773838
          </span>
          <div className="flex flex-row mt-3 space-x-2">
            <div>
              <FbSvg />
            </div>
            <div>
              <InstSvg />
            </div>
            <div>
              <TwitterSvg />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
