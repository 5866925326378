import { lazy } from "react";

const AdminPath = "/admin";
const CustomerPath = "/app"

export const RouteEnum = {
  Landing: "/",
  Flight: "/flight",
  FlightSearch: "/flight-result",
  Order: "/order",
  FlightBooking: "/booking",
  TermsConditions: "/terms-condition-policy",
  Print: "/print",
  Login: "/login",
  BookingRef: "/",
  Admin: AdminPath,
  AdminUsers: `${AdminPath}/users/`,
  AdminBooking: `${AdminPath}/bookings/`,
  AdminTransaction: `${AdminPath}/transactions`,
  AdminConfig: `${AdminPath}/configs/`,

  // Customer paths
  CustomerBooking: `${CustomerPath}/bookings`,
  CustomerProfile: `${CustomerPath}/profile`,
};

const FlightRoutes = [
  {
    path: RouteEnum.Landing,
    component: lazy(() => import("pages/landing/LandingPage")),
    exact: true,
  },
  {
    path: RouteEnum.FlightSearch,
    component: lazy(() => import("pages/flight/FlightResults")),
    exact: true,
  },
  {
    path: RouteEnum.FlightBooking,
    component: lazy(() => import("pages/trip/FlightBookingContainer")),
    exact: true,
  },

  {
    path: RouteEnum.Print,
    component: lazy(() => import("pages/trip/PrintContainer")),
    exact: true,
  },

  {
    path: RouteEnum.Login,
    component: lazy(() => import("pages/account/Login")),
    exact: true,
  },

];

export const AdminRoutes = [
  {
    path: AdminPath,
    component: lazy(() => import("pages/admin/pages/home/DashboardContainer")),
    exact: true,
  },
  {
    path: RouteEnum.AdminUsers,
    component: lazy(() => import("pages/admin/pages/users/UsersContainer")),
    exact: true,
  },
  {
    path: RouteEnum.AdminBooking,
    component: lazy(() =>
      import("pages/admin/pages/booking/AdminBookingsContainer")
    ),
    exact: true,
  },

  {
    path: RouteEnum.AdminConfig,
    component: lazy(() =>
      import("pages/admin/pages/config/AirlineConfig")
    ),
    exact: true,
  },
];

export const CustomerRoutes = [
  {
    path: CustomerPath,
    component: lazy(() => import("pages/customer/booking/CustomerBookingContainer")),
    exact: true,
  },
  {
    path: RouteEnum.CustomerBooking,
    component: lazy(() => import("pages/customer/booking/CustomerBookingContainer")),
    exact: true,
  },
  {
    path: RouteEnum.CustomerProfile,
    component: lazy(() => import("pages/customer/profile/CustomerProfileContainer")),
    exact: true,
  },
]

const ROUTES = [...FlightRoutes];

export default ROUTES;
