import { getToken, setToken as setCookieToken } from "common/Cookies";
import AppContext from "context";
import { useContext, useEffect, useState } from "react";
import { SET_TOKEN } from "reducer";

const useToken = () => {
  const [token, setToken] = useState(null);
  const { dispatch } = useContext(AppContext);

  const dispatchSetToken = (authToken) => {
    dispatch({ type: SET_TOKEN, payload: authToken });
    setToken(authToken);
    setCookieToken(authToken);
  };
  useEffect(() => {
    const cookieToken = getToken();
    if (cookieToken) {
      dispatchSetToken(cookieToken);
    }
  }, []);
  return {
    token,
    dispatchSetToken,
  };
};

export default useToken;
